import PropTypes from "prop-types"
import React, { useRef } from "react"
import { Link } from "gatsby"
import { Menu, Close } from "@material-ui/icons"
// import { VscFilePdf } from "react-icons/vsc"

import styles from "../styles/header.module.scss"
import navStyles from "../styles/nav.module.scss"
import Button from "../components/button"
import Wrapper from "../components/wrapper"
import { useDetectOutsideClick } from "../components/useDetectOutsideClick"
// import Logo from "../svg/logo.svg"

const Header = ({ menuLinks }) => (
  <header id="top" className={styles.header}>
    <Nav menuLinks={menuLinks} />
  </header>
)

const Nav = ({ menuLinks }) => {
  const menuRef = useRef(null)
  const [isOpen, setIsOpen] = useDetectOutsideClick(menuRef, false)
  const onClickHamburger = () => setIsOpen(!isOpen)
  return (
    <div className={navStyles.navbar}>
      <Wrapper sizeMax className="navbar-inner">
        <button
          onClick={onClickHamburger}
          className={`menu-trigger ${isOpen ? "active" : "inactive"}`}
          aria-label={`${isOpen ? "open" : "close"} navigation menu`}
        >
          {isOpen ? (
            <Close className="menu-icon menu-icon-close" id="close-menu" />
          ) : (
            <Menu className="menu-icon menu-icon-open" id="open-menu" />
          )}
        </button>
        <Button
          id="food-menu"
          className="dining-menu"
          href="/menu/"
        >
          Dining Menu
          {/* <VscFilePdf className="icon icon-pdf">PDF</VscFilePdf> */}
        </Button>
      </Wrapper>
      <Wrapper className="menu-wrapper">
        <nav
          className={`${navStyles.menu} ${isOpen ? navStyles.menuActive : ""}`}
        >
          <Wrapper className="nav-wrapper">
            <div ref={menuRef}>
              <Wrapper sizeMax>
                <ul className="main-menu">
                  {menuLinks.map(link => (
                    <li className="menu-item" key={link.name}>
                      <Link to={link.link} activeClassName="current">
                        {link.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Wrapper>
            </div>
          </Wrapper>
        </nav>
      </Wrapper>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
