// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import styles from "../styles/button.module.scss"

const Button = ({ className, href, target, rel, style, children }) => (
  <a className={`${styles.button} ${className}`} style={style} href={href} target={target} rel={rel}>
    {children}
  </a>
)

Button.propTypes = {
  href: PropTypes.string,
}

Button.defaultProps = {
  className: ``,
  href: `#`,
}

export default Button
