import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { Img } from "gatsby-image"
import { Helmet } from "react-helmet"
import { MDXProvider } from "@mdx-js/react"
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav"
import "@reach/skip-nav/styles.css"

import "../styles/global.scss"
import styles from "../styles/layout.module.scss"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import Button from "../components/button"
import Wrapper from "../components/wrapper"

// import LogRocket from "logrocket"
// import setupLogRocketReact from "logrocket-react"

// https://docs.logrocket.com/docs/quickstart
// setupLogRocketReact(LogRocket)

const globalComponents = { Link, SEO, Img, Button }

const Layout = ({ nowrap, strip, children, className}) => {
  // useEffect(() => {
    // Switch condition to test LogRocket locally
    // if (window.location.hostname === "localhost") {
    // if (window.location.hostname.match(/^(.*\.)*brotherspublic\.com$/)) {
    //   console.info("Init LogRocket")
    //   LogRocket.init("v3rdqx/brotherspubliccom")
    // }
  // }, [])
  let mainContent = (
    <Wrapper sizeMax>
      <Wrapper sizeReadable>{children}</Wrapper>
    </Wrapper>
  )
  if (nowrap) {
    mainContent = children
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              menuLinks {
                name
                link
              }
            }
          }
        }
      `}
      render={data => (
        <MDXProvider components={globalComponents}>
          {/* <AppleTouch /> */}
          <Helmet>
            <link rel="preload" href="/fonts/fonts.css" as="style" />
            <link rel="prefetch" href="/brothers-menu.pdf" as="document" />

            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-touch-fullscreen" content="yes" />
            <meta name="apple-mobile-web-app-title" content="Brothers Public Kitchen" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta
              name="apple-mobile-web-app-status-bar-style"
              content="dark"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-2048-2732.jpg"
              media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-2732-2048.jpg"
              media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-1668-2388.jpg"
              media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-2388-1668.jpg"
              media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-1536-2048.jpg"
              media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-2048-1536.jpg"
              media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-1668-2224.jpg"
              media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-2224-1668.jpg"
              media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-1620-2160.jpg"
              media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-2160-1620.jpg"
              media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-1284-2778.jpg"
              media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-2778-1284.jpg"
              media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-1170-2532.jpg"
              media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-2532-1170.jpg"
              media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-1125-2436.jpg"
              media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-2436-1125.jpg"
              media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-1242-2688.jpg"
              media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-2688-1242.jpg"
              media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-828-1792.jpg"
              media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-1792-828.jpg"
              media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-1242-2208.jpg"
              media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-2208-1242.jpg"
              media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-750-1334.jpg"
              media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-1334-750.jpg"
              media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-640-1136.jpg"
              media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-1136-640.jpg"
              media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />

            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-2048-2732.png"
              media="(prefers-color-scheme: dark) and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-2732-2048.png"
              media="(prefers-color-scheme: dark) and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-1668-2388.png"
              media="(prefers-color-scheme: dark) and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-2388-1668.png"
              media="(prefers-color-scheme: dark) and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-1536-2048.png"
              media="(prefers-color-scheme: dark) and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-2048-1536.png"
              media="(prefers-color-scheme: dark) and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-1668-2224.png"
              media="(prefers-color-scheme: dark) and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-2224-1668.png"
              media="(prefers-color-scheme: dark) and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-1620-2160.png"
              media="(prefers-color-scheme: dark) and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-2160-1620.png"
              media="(prefers-color-scheme: dark) and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-1284-2778.png"
              media="(prefers-color-scheme: dark) and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-2778-1284.png"
              media="(prefers-color-scheme: dark) and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-1170-2532.png"
              media="(prefers-color-scheme: dark) and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-2532-1170.png"
              media="(prefers-color-scheme: dark) and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-1125-2436.png"
              media="(prefers-color-scheme: dark) and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-2436-1125.png"
              media="(prefers-color-scheme: dark) and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-1242-2688.png"
              media="(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-2688-1242.png"
              media="(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-828-1792.png"
              media="(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-1792-828.png"
              media="(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-1242-2208.png"
              media="(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-2208-1242.png"
              media="(prefers-color-scheme: dark) and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-750-1334.png"
              media="(prefers-color-scheme: dark) and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-1334-750.png"
              media="(prefers-color-scheme: dark) and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-640-1136.png"
              media="(prefers-color-scheme: dark) and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href="/image/apple-touch/apple-splash-dark-1136-640.png"
              media="(prefers-color-scheme: dark) and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />
          </Helmet>

          <div className={`${styles.layout} ${className}`}>
            <SkipNavLink />
            {!strip && <Header menuLinks={data.site.siteMetadata.menuLinks} />}

            <main id="main" className="main-content">
              <SkipNavContent />
              {mainContent}
            </main>
            {!strip && <Footer />}
          </div>
        </MDXProvider>
      )}
    />
  )
}

Layout.defaultProps = {
  strip: false,
  nowrap: false,
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
