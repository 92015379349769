// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React, { useEffect } from "react"

import styles from "../styles/footer.module.scss"
import Shocker from "../svg/stamp-bw.svg"
import Wrapper from "../components/wrapper"
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa"
import { FaInstagram, FaFacebookSquare } from "react-icons/fa"
import { SiGooglemaps } from "react-icons/si"

const Social = () => (
  <div id="social" className={styles.social}>
    <div className="icon-container">
      <a
        href="https://www.instagram.com/brotherspublickitchen/"
        target="_blank"
        rel="noreferrer"
        title="Brothers Public Kitchen on Instagram"
      >
        <FaInstagram className="icon icon-social" />
      </a>
      <a
        href="https://www.facebook.com/brotherspublickitchen"
        target="_blank"
        rel="noreferrer"
        title="Brothers Public Kitchen on Facebook"
      >
        <FaFacebookSquare className="icon icon-social" />
      </a>
    </div>
    <p>
      Follow us on Facebook and Instagram for all the latest updates, upcoming
      events, and more.
    </p>
  </div>
)

const Footer = () => {
  const shockTop = ev => {
    console.log("%c🥚", "font-size: 36px")
    const shockerEl = ev.target.closest(".shock")
    shockerEl.classList.add("active")
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
    setTimeout(() => {
      shockerEl.classList.remove("active")
    }, 1000)
  }
  useEffect(() => {}, [])
  return (
    <footer className={styles.footer} id="bottom">
      <Wrapper sizeMax>
        <div className="footer-layout">
          <div className="info">
            <h2 className="heading">Summer Hours:</h2>
            <p>
              <strong>Lunch</strong>: Friday, Saturday, &amp; Sunday, 11am to
              2pm
              <br />
              <strong>Dinner</strong>: Tuesday &ndash; Sunday, 5pm to 9pm
              <br />
              <strong>Closed Monday</strong>
            </p>
            <h3 className="heading">Happy Hour:</h3>
            <p>Friday, 3pm to 5pm</p>
            <p>
              <em>Menu subject to change without notice</em>
            </p>
          </div>
          <div className="contact">
            <h2 className="heading">Contact Us:</h2>
            <address className="">
              <p className="contact-item">
                <a href="tel:+19252531225" title="Call Us">
                  <FaPhoneAlt className="icon icon-contact" />
                  <span className="">(925) 253-1225</span>
                </a>
              </p>
              <p className="contact-item">
                <a
                  href="https://goo.gl/maps/hGA4MfyqBwt9x9q59"
                  target="_blank"
                  rel="noreferrer"
                  title="Find Us On Google Maps"
                >
                  <SiGooglemaps className="icon icon-contact" />
                  <span className="">
                    2 Theatre Square #144
                    <br />
                    Orinda, CA 94563
                  </span>
                </a>
              </p>
              <p className="contact-item nowrap">
                <a href="mailto:info@brotherspublic.com" title="Email Us">
                  <FaEnvelope className="icon icon-contact" />
                  <span className="">info@brotherspublic.com</span>
                </a>
              </p>
            </address>
          </div>
          <div className="follow">
            <Social />
          </div>
          <div className="imprint">
            <div className="imprint-container">
              <p className="copyright">
                © 2020&mdash;{new Date().getFullYear()} Brothers{" "}
                <span>
                  Public Kitchen&nbsp;
                  <Shocker className="shock" onClick={shockTop} />
                </span>
              </p>
            </div>
          </div>
        </div>
      </Wrapper>
    </footer>
  )
}

// Footer.propTypes = {}
// Footer.defaultProps = {}

export default Footer
