import React from "react"
import PropTypes from "prop-types"

import "../styles/global.scss"
import styles from "../styles/wrapper.module.scss"

const Wrapper = ({ className, sizeMax, sizeReadable, children }) => {
  // If someone adds a class to <Wrapper> with prop, use both
  let classes = `${styles.base} ${className}`
  if (!!sizeMax) {
    classes += ` ${styles.layout}`
  }
  if (!!sizeReadable) {
    classes += ` ${styles.prose}`
  }
  return (
    <div className={classes}>
      {children}
    </div>
  )
}

Wrapper.propTypes = {
  children: PropTypes.node,
}

Wrapper.defaultProps = {
  className: ``,
  sizeMax: false,
  sizeReadable: false,
}

export default Wrapper
